const TWEAKWISE_PREFIX = '#twn|?';

const parseTweakwiseHash = (hash) => {
  if (!hash.startsWith(TWEAKWISE_PREFIX)) {
    return null;
  }

  return new URLSearchParams(hash.replace(TWEAKWISE_PREFIX, ''));
};

export const getQuery = (hash) => {
  const params = parseTweakwiseHash(hash);

  if (!params) {
    return null;
  }

  return params.get('tn_q');
}

export const resetQuery = () => {
  const params = parseTweakwiseHash(window.location.hash);
  if (!params) {
    return;
  }

  params.delete('tn_q');
  const newHash = params.toString() ? `${TWEAKWISE_PREFIX}${params}` : '';
  const newUrl = `${window.location.origin}${window.location.pathname}${newHash}`;
  window.history.replaceState({}, '', newUrl);
}
