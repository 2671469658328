import Cookies from 'js-cookie'

import gaTrack from './ga'
import gtmTrack from './gtm'
import fbqTrack from './fbq'

const debug = () => {
  Cookies.get('development')
}

const enabled = () => {
  return !debug()
}

const track = (event, data) => {
  if (document.readyState !== 'complete') {
    if (debug()) console.log('Tracking.track', 'page still loading', event)

    window.addEventListener('load', () => {
      // Try again when the page has loaded
      track(event, data)
    })

    return
  }

  if (debug()) console.log('Tracking.track', event, data)

  if (enabled()) {
    gaTrack(event, data)
    gtmTrack(event, data)
    fbqTrack(event, data)
  }
}

export { track }
