import React, { useState, useEffect } from 'react'

// Libraries
import { take, sortBy, reverse } from 'lodash'

// Utils
import { isFilterPresent, filterPath } from './utils'

// Components
import Filter from './Filter'

const FilterGroup = ({ name, slug, filters, selectedFilters, handleToggleFilter, counters, navigate }) => {
  if (!name) return null

  if (counters) {
    filters = sortBy(filters, (filter) => {
      const selected = isFilterPresent(filter.slug, slug, selectedFilters)
      let count = counters[`${slug}--${filter.slug}`] || 0

      if (selected) count = count + 10000

      return count
    })
    filters = reverse(filters)
  }

  const renderedFilters = filters.map((filter) => {
    const selected = isFilterPresent(filter.slug, slug, selectedFilters)
    const path = filterPath(filter.slug, slug)
    const count = (counters || {})[`${slug}--${filter.slug}`] || 0

    if (counters && count === 0) { return }

    return (
      <Filter
        key={filter.id}
        {...filter}
        selected={selected}
        count={count}
        path={path}
        handleToggleFilter={handleToggleFilter.bind(null, filter.slug, slug)}
        navigate={navigate}
      />
    )
  }).filter((filter) => filter)

  return (
    <>
      {renderedFilters.length > 0 &&
      <div className="product-filter">
        <div className="product-filter__category">{name}</div>
        <div className="product-filter__options">{renderedFilters}</div>
      </div>}
    </>
  )
}

export default FilterGroup
